import React from "react"
import styled, { keyframes } from "styled-components"
// import Expand from "./expand"
// import ArticleList from "./article-list"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FaSpinner } from "react-icons/fa"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Content = ({ topics = [] }) => {
  var {
    wordpressAcfOptions: {
      options: { tagline },
    },
  } = useStaticQuery(TAGLINE)
  const topicsWithIndex = topics.filter(
    topic =>
      topic.acf &&
      topic.acf.topics &&
      topic.acf.topics.index_order &&
      topic.articles !== null
  )
  const topicsWithoutIndex = topics.filter(
    topic => topic.acf === null && topic.articles !== null
  )
  const topicsWithIndexSorted = topicsWithIndex.sort((a, b) =>
    a.acf?.topics?.index_order > b.acf?.topics?.index_order ? 1 : -1
  )
  const finalTopics = [...topicsWithIndexSorted, ...topicsWithoutIndex]

  return (
    <Container>
      <p className="tagline">{tagline}</p>
      <h2>Index</h2>
      <Grid>
        {finalTopics
          .filter(({ articles, parent_element }) => articles && !parent_element)
          .map(({ name, id, articles }) => (
            <Section key={id}>
              <h3>{name}</h3>
              {articles.map(el => (
                <LoadLink {...el} key={el.slug} />
              ))}
            </Section>
          ))}
      </Grid>
    </Container>
  )
}

const LoadLink = ({ title, slug }) => {
  const [loading, setLoading] = React.useState(false)
  return (
    <Link to={slug} onClick={() => setLoading(true)}>
      <span dangerouslySetInnerHTML={{ __html: title }} />{" "}
      {loading && <FaSpinner />}
    </Link>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const Grid = styled.div`
  column-count: 2;
  column-gap: 40px;
  @media only screen and (max-width: 786px) {
    column-count: 1;
  }
`

const rotate = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Section = styled.div`
  margin: 0 0 40px 0;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  h3 {
    font-size: 25px;
    font-weight: 500;
    border-bottom: 2px solid #eee;
    color: ${({ theme: { getColor } }) => getColor(900)};
    line-height: 1.2;
    margin: 0 0 20px 0;
  }
  a {
    background: #eee;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 5px 0;
    text-decoration: none;
    color: black;
    display: flex;
    width: calc(100% - 20px);
    justify-content: space-between;
    align-items: center;
    transition: all 100ms ease-in-out;
    &:hover {
      color: ${({ theme: { getColor } }) => getColor(300, true)};
      background-color: ${({ theme: { getColor } }) => getColor(300)};
    }

    svg {
      animation: ${rotate} 2s linear infinite;
    }
  }
`

const Container = styled.div`
  padding: 40px;
  h2 {
    font-family: "Poppins", sans-serif;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
  }
  .tagline {
    color: rgba(0, 0, 0, 0.75);
    text-align: center;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 600;
    margin: 20px 0 0 0;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const TAGLINE = graphql`
  {
    wordpressAcfOptions {
      options {
        tagline
      }
    }
  }
`

export default Content
