import React from "react"
import Layout from "../components/layout"
import Content from "../components/shared/content"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const IndexPage = () => {
  const {
    allWordpressWpTopics: { nodes: topics },
    wordpressAcfOptions: {
      options: { header_logo },
    },
  } = useStaticQuery(TOPICS_WITH_ARTICLES)

  return (
    <Layout
      seo={{ title: "Contents" }}
      mainHeaderComponent={
        <Header>
          <div className="logo">
            <Img fluid={header_logo?.localFile?.childImageSharp?.fluid} />
          </div>
        </Header>
      }
      mainContent={<Content topics={topics} />}
    />
  )
}

const Header = styled.div`
  display: flex;
  width: 900px;
  .logo {
    width: 300px;
    margin: auto;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 786px) {
    width: auto;
    padding-left: 20px;
  }
  @media (max-width: 390px) {
    .logo {
      width: 250px;
    }
  }
  @media (max-width: 345px) {
    .logo {
      width: 200px;
    }
  }
  @media (max-width: 290px) {
    .logo {
      width: 160px;
    }
  }
`

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const TOPICS_WITH_ARTICLES = graphql`
  query {
    allWordpressWpTopics(
      filter: { slug: { nin: ["dummy", "sub-dummy", "fake", "article-dummy"] } }
    ) {
      nodes {
        wordpress_id
        slug
        name
        articles {
          wordpress_id
          slug
          title
          topics {
            id
            name
          }
        }

        acf {
          topics {
            index_order
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        front_end_url
        publication_name
        publication_overview
        header_logo {
          slug
          localFile {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
export default IndexPage
